import React, { useEffect } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import { color, breakpoints, NavUlStyles, NavStylesNew } from '../../utils/style';
import Logo from '../Header/LogoSvg';
import Hamburger from './Icons/Hamburger';
import MobileContactLink from './Icons/MobileContactLink';
import MobileCallLink from './Icons/MobileCallLink';
import TopSearch from './Icons/TopSearch';
import HeaderContacts from '../Header/HeaderContactsNew';
import MobileCloseLink from '../Header/MobileCloseLink';
import SearchForm from '../Search/Form';
import Gift from '../../icons/svg/Gift';

const NavWrap = styled('div')`
  position: relative;
  z-index: 1;
  .logo {
    display: inline-block;
    margin: 0 2% 0 0;
    width: 255px;
    max-width: 54%;
    padding: 15px 0;
    position: relative;
    z-index: 1;
    svg {
      max-width: 100%;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    position: static;
    margin-bottom: 74px;
    .logo {
      padding: 10px;
      max-width: none;
      margin: 0;
    }
    &.search-open {
      position: relative;
      z-index: 100;
      width: 100%;
    }
  }
  
  @media (max-width: ${breakpoints.xs}) {
    position: static;
    margin-bottom: 74px;
    .logo {
      position: absolute;
      left: 21.5%;
      top: 8px;
      padding: 0;
      margin: 0;
      max-width: 54%;
      margin-top: 5px;
    }
  }
`;

const DesktopWrapper = styled('div')`
  max-width: 1244px;
  padding: 12px 10px 10px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  .desktop-limiter {
    flex-basis: 45%;
    .header-promo-link {
      float: right;
      margin: 12px -15px 0 0;
      background: ${color.transparentWhite1};
      border: none;
      padding: 14px 23px;
      span {
        display: inline-block;
        vertical-align: top;
        letter-spacing: 1px;
        line-height: 20px;
        margin-left: 10px;
      }
      svg {
        display: inline-block;
        vertical-align: bottom;
      }
      &:hover, &:active, &:focus, &.active {
        background: ${color.transparentWhite2};
        span {
          text-shadow: 0 0 9px ${color.brightRed};
          color: ${color.lightPeach};
        }
      }
    }
  }
  
  @media (max-width: ${breakpoints.lg}) {
    .desktop-limiter {
      flex-basis: 36%;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    z-index: 2;
    .desktop-limiter {
      flex-basis: 100%;
      text-align: center;
      padding-right: 70px;
      .header-promo-link {
        float: left;
        padding: 12px;
        margin-left: 20px;
        span {
          display: none;
        }
      }
    }
    .mobile-fixed {
      background: ${color.brownRed};
    }
    .header-contacts-menu.open {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      background: ${color.brownRed};
      padding-top: 0;
      height: 75px;
    }
  }
  
  @media (max-width: ${breakpoints.xs}) {
    .desktop-limiter {
      position: relative;
      height: 70px;
      .header-promo-link {
        margin: 14px -14px 0 15px;
      }
    }
  }
`;


const BottomHeader = styled('div')`
  margin: 0;
  transition: all 0.35s ease-out;
  .bottom-nav {
    display: none;
  }
  
  @media (min-width: ${breakpoints.mdMin}) {
    &.fixed {
      margin: 0;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 10;
      background: ${color.brownRed};
      .top-nav {
        margin: 0 auto;
      }
      .submenu {
        top: 45px;
      }
    }
  }
  @media (max-width: ${breakpoints.md}) {
    margin: 0;
    background: ${color.brownRed};
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    border-top:1px solid ${color.darkRed};
    .bottom-nav {
      display: block;
      height: 48px;
      a, button {
        -webkit-appearance: none;
        border: 0;
        background: ${color.brownRed};
        width: 50%;
        display: block;
        float: left;
        text-align: center;
        padding: 9px 5px 0;
        &:active, &:focus {
          outline:none;
          svg {
            path {fill: ${color.white} !important;}
            filter: drop-shadow(1px 1px 6px ${color.peach});
          }
        }
      }
      a {
        position: relative;
      }
      a::before { 
        content: "";
        position: absolute;
        left:0;
        top:6px;
        height: 35px;
        width: 1px;
        background: ${color.transparentWhite1};
      }
    }
    
  }
`;

const NavUlWrap = styled('div')`
  ${NavUlStyles}
`;


const Nav = styled('ul')`
  ${NavStylesNew}
  
`;


const unBlockLayout = () => {
  const layout = document.getElementById('all-content');
  layout.classList.remove('inter-blocked');
};

const closeMobileMenu = () => {
  let elems = document.querySelectorAll('.open');
  [].forEach.call(elems, function (el) {
    el.classList.remove('open');
  });
  const navWrap = document.getElementById('nav-wrap');
  navWrap.classList.remove('search-open');
  unBlockLayout();
};

const closeMenuItems = () => {
  let elems = document.querySelectorAll('li.open-item');
  [].forEach.call(elems, function (el) {
    el.classList.remove('open-item');
  });
};

const getClosest = (elem, selector) => {
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function (s) {
        let matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) {
        }
        return i > -1;
      };
  }

  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;

};


const closePopupHandler = () => {
  const layout = document.getElementById('all-content');
  if (layout) {
    layout.classList.remove('popup-blocked');
  }

  let activePopup = document.querySelector('.popup-open.popup-window');
  if (activePopup) {
    activePopup.classList.remove('popup-open');
  }
};

const handleScroll = () => {
  const elToFix = document.getElementById('toFix');
  const elToFixMobile = document.getElementById('toFixMobile');
  if (window.pageYOffset > elToFix.offsetTop) {
    elToFix.classList.add('fixed');
  } else {
    elToFix.classList.remove('fixed');
  }
  if (window.pageYOffset > elToFixMobile.offsetTop) {
    elToFixMobile.classList.add('mobile-fixed');
  } else {
    elToFixMobile.classList.remove('mobile-fixed');
  }
};


const MainMenu = () => {
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('click', function (event) {
      if (event.target.classList.contains('accordion-title')) {
        let parentEl = event.target.parentNode;
        if (parentEl.classList.contains('accordion-open')) {
          parentEl.classList.remove('accordion-open');
        } else {
          parentEl.classList.add('accordion-open');
        }
      }
    });
    /* Same for all popup windows */
    window.addEventListener('mouseup', function (event) {
      let activePopup = document.querySelector('.popup-open');
      if (event.target !== activePopup && !getClosest(event.target, '.popup-open')) {
        closePopupHandler();
      }
    });
    window.addEventListener('touchend', function (event) {
      let item = document.getElementById('nav');
      let activeMenuItem = document.querySelectorAll('.open-item');
      if (event.target !== item && activeMenuItem.length && !getClosest(event.target, '#nav')) {
        closeMenuItems();
      }
    });
    /* Same for all popup windows */
    window.addEventListener('touchend', function (event) {
      let activePopup = document.querySelector('.popup-open');
      if (event.target !== activePopup && !getClosest(event.target, '.popup-open')) {
        closePopupHandler();
      }
    });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });
  return (
    <StaticQuery
      query=
        {graphql`{
        allWordpressWpApiMenusMenusItems(filter: {slug:{eq:"main"}}) {
          nodes {
            id
            name
            slug
            items {
              title
              classes
              attr
              object_slug
              path
              target
              url
              wordpress_children {
                attr
                classes
                description
                path
                title
                xfn
                object_slug
                wordpress_id
                target
                url
              }
            }
          }
        }
      }`}
      render={data => (
        <NavWrap className="nav-wrap" id="nav-wrap">
          <DesktopWrapper>
            <div className="desktop-limiter" id="toFixMobile">
              <Link to={process.env.FREESPIN_TERMS_URL_RELATIVE} activeClassName="active" className="header-promo-link button transparent-button" title="Our promotions">
                <Gift/>
                <span>Promosi</span>
              </Link>
              <Logo to="/" text={`${process.env.LOGO_ALT}`}/>
            </div>
            <HeaderContacts>
              <div className="service-wrapper header-search-wrapper">
                <MobileCloseLink handlerFunction={closeMobileMenu}/>
                <SearchForm handlerFunction={closeMobileMenu}/>
              </div>
            </HeaderContacts>

            <TopSearch/>
          </DesktopWrapper>
          <BottomHeader id="toFix">
            <div className="bottom-nav">
              <Hamburger/>
              <MobileContactLink/>
            </div>

            <NavUlWrap id="nav" className="top-nav">
              <Nav className="top-nav-ul">
                <li className="mobile-only">
                  <Link to={process.env.FREESPIN_TERMS_URL_RELATIVE} activeClassName="active" className="header-promo-link button transparent-button" title="Our promotions">
                    <Gift/>
                    <span>Promosi</span>
                  </Link>
                  <Link className="button transparent-button register-button" to="/register/" text="Daftar">
                    <span>Daftar</span>
                  </Link>
                </li>
              </Nav>
            </NavUlWrap>
          </BottomHeader>
        </NavWrap>
      )}
    />
  );
};


export default MainMenu;
